import React, { Component } from 'react';
import service from '../Services/service';
import DataTable from 'react-data-table-component';

const columns = [
  {
    name: 'Rank',
    selector: 'rank',
    center: true,
  },
  {
    name: 'channel',
    selector: 'channel'
  },
  {
    name: 'subs',
    selector: 'subs',
    center: false,
  },
  {
    name: 'vids',
    selector: 'vids'
  },
  {
    name: 'likes',
    selector: 'likes',
  },
  {
    name: 'views',
    selector: 'views',
    sortable: true,
    center: false,
  },
];

class StudentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    this.getData();
    this.interval = setInterval(() => {
      localStorage.clear();
      this.getData();
    }, process.env.REACT_APP_REFRESH_RATE);
  }

  async getData() {
    const data = await service('../data/students-scores.json');
    const sortedData = data
      .map(student => ({
        channel: student.channel,
        subs: student.channel_subscribers,
        vids: student.channel_videos,
        likes: student.likes,
        views: Number(student.views) // Ensure views are treated as numbers
      }))
      .sort((a, b) => b.views - a.views) // Sort by views descending
      .slice(0, 25) // Take the top 25
      .map((student, index) => ({
        ...student,
        rank: index + 1 // Assign rank based on sorted position
      }));

    this.setState({ data: sortedData });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div className="student-container">
        <h2>PROD's Leaderboard</h2>
        <DataTable
          columns={columns}
          highlightOnHover
          pointerOnHover
          striped
          className="student-table"
          data={this.state.data}
          pagination // Enable pagination
          paginationPerPage={25} // Set rows per page to 25
          paginationRowsPerPageOptions={[10, 25, 50, 100]} // Include 25 in pagination options
        />
      </div>
    );
  }
}

export default StudentList;
